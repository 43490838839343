//
// map.js
//


const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoibWFyY2VsMTcwNyIsImEiOiJja29yOGVyMWQwa24zMnlubDZuMjM5ZDB5In0.jtD11Jr67Z6mPMf3xVuZFQ';

maps.forEach(map => {
  const elementOptions = map.dataset.map;

  console.log(elementOptions)

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: new mapboxgl.LngLat(13.128987, 47.99081),
    zoom: 16,
    interactive: true
  };

  const options = {
    ...defaultOptions,
    ...elementOptions
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  var m = new mapboxgl.Map(options);

  var blumenwiesnMarker = new mapboxgl.Marker({
    color: "#A5862B"
  }).setLngLat([13.128687, 47.99001])
  .setPopup(new mapboxgl.Popup({
    className: "markerPopup",
    closeButton: false
  }).setHTML("<h6>Blumenwiesn Cafe & Imbiss</h6>"))
  .addTo(m);

  var el = document.createElement('div');
  el.className = 'marker';

  new mapboxgl.Marker(el).setLngLat([13.128927, 47.991848])
  .setPopup(new mapboxgl.Popup({
    className: "markerPopup",
    closeButton: false
  }).setHTML("<h6>Blumenwiesn Parkplatz</h6>"))
  .addTo(m);


});
